import React from "react"

import Layout from "../components/Layout"
import TermsConditions from "../components/TermsConditions"
import SEO from "../components/seo"

const TermsConditionsPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Terms Conditions" />
    <TermsConditions />
  </Layout>
)

export default TermsConditionsPage
