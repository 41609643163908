import React from "react"
import ImageSection from "../ImageSection"

import styles from "./TermsConditions.module.css"

const TermsConditionsView = () => {
  return (
    <div>
      <ImageSection />
      <section className={styles.section}>
        <h3>Terms & Conditions</h3>
        <p>
          Use of this Site constitutes acknowledgement that You have read,
          understand, and agree to the owner of this website’s Terms &
          Conditions and Privacy Policy. These Terms & Conditions apply to
          Handicaploan.com (also referred to as “Company” or “Site”). It
          notifies you (also ‘You’ and/ or ‘Your’) about the terms and
          conditions of our services. Please read these Terms & Conditions in
          full before using this Site. If you do not agree with the Company’s
          Terms & Conditions or Privacy Policy, do not continue to use this Site
          or seek its services.
        </p>
        <h3>Services</h3>
        <p>
          ?????? is NOT a lender and does not broker loans. The Company seeks to
          connect You with prospective lenders that may be able to offer You a
          shorter-duration loan. The Site doesn’t guarantee that You will be
          offered a loan and doesn’t guarantee any amount of a loan. The
          information You provide on your request form may be shared with more
          than one lender in an attempt to find a lender who may be able to
          offer you a shorter-duration loan. Your use of the Company’s services
          includes providing relevant and necessary information which may
          include, but is not limited to: first and last name, date of birth,
          mobile telephone number, physical address, Social Security number,
          driver’s license number and state, email address, employment
          information, active military information, information about home
          ownership, frequency and amount of paychecks, and information about
          your bank account. This information will be used to try to connect You
          with a shorter-duration loan lender. Submitting your information to
          this Site means You allow the Company, third party lenders and
          marketers to contact You about loans and related products and
          services. Once your information is submitted, it will be reviewed by
          third party lenders who will then determine if You meet their
          requirements for a shorter-duration loan. Lenders may use your
          personal information to verify your identity and perform a credit
          check through a credit bureau. Submission of your information
          constitutes your agreement for your information to be used in these
          ways. If You are found to be eligible for a loan, You will be
          redirected to a new page with the lender’s information or redirected
          to a third party site to continue with the process. This Company
          doesn’t guarantee that the loan offer You may receive is the best
          available or has the best terms or rates. This Company doesn’t
          guarantee the product, price, rates, fees, availability or any other
          terms of a lender’s loan products. The Company strongly encourages You
          to shop around for rates and terms that best suit your financial
          situation. This Company is not involved with any agreement you may
          enter into with a lender. Your interactions with your lender are
          governed by their privacy policy, terms, conditions, and other
          policies. Please reach out to your lender directly with any questions
          and concerns.
        </p>
        <h3>Telephone Consumer Protection Act Compliance</h3>
        <p>
          By submitting your information to this Site, You are providing your
          express written consent under the Telephone Consumer Protection Act to
          receive telemarketing calls, text messages, SMS messages, and other
          communications as governed by TCPA, from this Company, its lenders and
          marketers. Please see the “Opt-In/Opt-Out of Communication” section of
          this Site’s Privacy Policy for more information concerning TCPA.
        </p>
        <h3>Authorized Use</h3>
        <p>
          The Company authorizes You to use this Site for personal and
          non-commercial use only, as permitted by law. The Company strictly
          prohibits all other uses, including but not limited to the
          duplication, copy, reproduction, sale, resale, use, access, or
          additional exploitation of any material from this Site for any
          commercial purposes.
        </p>
        <h3>Intellectual Property Rights</h3>
        <p>
          The content of this Site is protected by applicable copyright laws in
          the United States and other countries. As such, use of this Site and
          this Company’s services confers no intellectual property rights on
          You. Content of this Site cannot be used without the written
          permission of the Company. All logos, trademarks, and service marks
          (referred to collectively as “Trademarks”) that are displayed on this
          Site are Trademarks of the Company, both registered and unregistered.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          ALL INFORMATION, CONTENT, PRODUCTS, AND SERVICES FOUND ON THIS SITE
          ARE OFFERED “AS IS” AND YOU ACCESS THEM AT YOUR OWN RISK. THE COMPANY
          DISCLAIMS ANY WARRANTY, EITHER IMPLIED OR DIRECTLY STATED REGARDING
          THIS SITE INCLUDING, BUT NOT LIMITED TO INFORMATION, CONTENT,
          PRODUCTS, AND SERVICES. THE COMPANY PROVIDES NO WARRANTY CONCERNING
          THE RESULTS AND EFFECTIVENESS OF THIS SITE OR ITS INFORMATION,
          CONTENT, PRODUCTS, AND SERVICES. USE OF THIS SITE IS AT YOUR OWN RISK.
          THIS COMPANY DOES NOT GUARANTEE, WARRANT, OR REPRESENT, THAT ANY OF
          ITS SERVICES WILL MEET YOUR REQUIREMENTS. THE COMPANY MAKES NO
          GUARANTEES THAT SERVICES WILL BE TIMELY, UNINTERRUPTED, SECURE, OR
          ERROR-FREE. NO GUARANTEES ARE MADE REGARDING FUNCTIONALITY OR DEFECTS
          IN THE OPERATION OF SERVICES OR RELATED SOFTWARE OR THAT ERRORS, IF
          ANY, WILL BE CORRECTED. THE COMPANY ALSO DOES NOT GUARANTEE, WARRANT,
          OR REPRESENT, THAT THIRD PARTY SERVICES WILL MEET YOUR REQUIREMENTS,
          OR BE TIMELY, UNINTERRUPTED, SECURE, OR ERROR-FREE. NO GUARANTEES ARE
          MADE REGARDING FUNCTIONALITY OR DEFECTS IN THE OPERATION OF THIRD
          PARTY SERVICES OR RELATED SOFTWARE OR THAT ERRORS WILL BE CORRECTED.
        </p>
        <h3>Limitation of Liabilities</h3>
        <p>
          THE COMPANY SHALL NOT BE HELD LIABLE OR RESPONSIBLE FOR DIRECT,
          INDIRECT, EXEMPLARY, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, OR SPECIAL
          DAMAGE RESULTING FROM YOUR USE AND/OR ACCESS TO THE COMPANY’S SITE OR
          SERVICES. THE FULL AND TOTAL LIABILITY, AS PERMITTED BY LAW, OF THE
          COMPANY FOR CLAIMS RESULTING FROM YOUR USE AND/ OR ACCESS TO THE
          COMPANY’S SITE OR SERVICES – WHETHER IN WARRANTY, CONTRACT, STRICT
          LIABILITY, TORT, OR OTHERWISE – IS LIMITED TO THE AMOUNT PAID TO THE
          COMPANY BY THE THIRD PARTY LENDER WHO PURCHASED YOUR LOAN INQUIRY FORM
          INFORMATION.
        </p>
        <h3>Third Party Links</h3>
        <p>
          Following third party links found on this Site is done at your own
          risk. The Company does not recommend or endorse and of the products or
          services found through third party links located on this Site. Such
          links are intended for convenience and reference. The Company is not
          responsible for content, information, software, or any other materials
          made available on third party sites.
        </p>
        <h3>Third Party Dealings</h3>
        <p>
          Dealings with third parties which result from use of the Site is done
          at your own risk. Any transactions between You and any third parties
          are not the responsibility of the Company. These transactions may
          include, but are not limited to, products, services, payment,
          conditions, warranties, or any terms and conditions. This Company will
          not be held responsible for your dealings with third parties whether
          or not such dealings result from your use of the Site.
        </p>
        <h3>Disputes and Resolution </h3>
        <p>
          Any dispute originating from or related to your use of or access to
          this Site and its services is governed by the state of California.
          Disputes will be settled exclusively and finally by arbitration
          according to the commercial arbitration rules of the American
          Arbitration Association then in effect at the time of the dispute in
          Los Angeles. The arbitrator shall have the power and authority to
          award damages connected with a Dispute which may not exceed actual
          compensatory damages and shall not multiply actual damages or award
          consequential, exemplary, or punitive damages. In addition, each party
          irrevocably waives any claim thereto. Any award shall be conclusive
          and final. An arbitration agreement should not be understood as an
          agreement to the consolidation or joinder of an arbitration under this
          agreement with an arbitration of claims or disputes of any non-party,
          regardless of the nature of the disputes. The Terms provided here
          state that all disputes between You and this Company will be resolved
          through arbitration. As a result, You forego your right to assert or
          defend your rights or go to court. You also forego the right to be
          part of or originate a class action suit. Your applicable rights will
          be decided by neutral arbitrators, not by a court of law, judge, or
          jury. By using the Company’s Site and services, You agree to these
          restrictions and conditions. If the arbitration provisions contained
          here become unenforceable or inapplicable, or in the event of any
          lawsuit between You and this Company or its Site, You agree that
          jurisdiction of this and any suit shall rest exclusively in the
          federal and state courts in California. You also agree that any
          actions or claim related to use of the Site or the Company’s services
          must be filed within one (1) year after the said action, cause, or
          claim, or else be forever barred.
        </p>
        <h3>Indemnification</h3>
        <p>
          Use of this Site constitutes your agreement to hold the Company
          blameless against any and all liability, expenses, loss, damages,
          actions, suits, claims, and proceedings, including, without
          limitation, reasonable attorneys’ fees and relevant costs, stemming
          from or related to any cause of action, allegation, claim or
          involving, without limitation, your use of the Site, or unauthorized
          or unlawful use of the Site and/or the Company’s services.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          The entire agreement between You and the Company are contained in
          these Terms & Conditions. Modification The Company retains the right
          to modify its Terms & Conditions and to add additional terms or
          conditions at any time without notice to You. All modifications and
          additions are effective immediately. If You disagree with any modified
          or additional term(s) or condition(s), please discontinue your use of
          the Site and its services. Non-Waiver; Severability Any failure on
          behalf of the Company to enforce these Terms and Conditions does not
          constitute the Company’s right to continue to enforce these Terms and
          Conditions. In the event that any of the Terms and Conditions are
          deemed to be illegal, invalid, or unenforceable, such terms and
          conditions will be nullified and the remaining terms and conditions
          will remain in full effect.
        </p>
      </section>
    </div>
  )
}

export default TermsConditionsView
